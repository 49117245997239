import Vue from "vue";

/*TITLE*/
document.title = "Ladera de Simón Verde | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Ladera de Simón Verde";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Ladera de Simón Verde";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon_home-salzburg--20230113040143.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "banyo_bottega-caliza--20230113040151.jpg";
Vue.prototype.$image_bath2 = "";
Vue.prototype.$image_room = "dormitorio_home-salzburg--20230113040105.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon_home-salzburg--20230113040143.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo_bottega-caliza--20230113040151.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
];

Vue.prototype.$gallery_images_room = [
  {
    src: "dormitorio_home-salzburg--20230113040105.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20magdalena%20murillo%20nº%2027%2041120%20gelves%20sevilla",
    text: "Calle Magdalena Murillo nº 27 - 41120 Gelves, Sevilla",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20real%20nº%2010%2041120%20gelves%20sevilla",
    text: "Calle Real nº 10 - 41120 Gelves, Sevilla",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:661776263",
    text: "661776263",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@solucionesinmobiliariasjcr.com",
    text: "info@solucionesinmobiliariasjcr.com",
  },
];
